<template>
    <div class="card">
      <div class="card-title">推荐交易对</div>
      <div class="recommended-pairs">
        <div v-if="recommendedPairs.length === 0" class="no-recommendations">
          <p>当前没有推荐交易对</p>
          <p>更新时间: {{ currentTime }}</p>
        </div>
        <div v-else v-for="pair in recommendedPairs" :key="pair[0]" class="recommended-pair">
          <h3>{{ pair[0] }}</h3>
          <div class="score">{{ Math.abs(pair[1]).toFixed(2) }}</div>
          <div :class="['direction', pair[2] === '做多' ? 'long' : 'short']">{{ pair[2] }}</div>
          <div class="signal-strength">信号强度: {{ pair[3].toFixed(1) }}%</div>
          <div class="holding-time">建议持有: {{ pair[4] }}</div>
          <div class="adx">ADX: {{ pair[5].toFixed(2) }}</div>
          <div class="volatility">波动率: {{ (pair[6] * 100).toFixed(2) }}%</div>
        </div>
      </div>
      <div class="score-explanation">
        得分范围：-10 到 +10。正数表示做多信号强度，负数表示做空信号强度。绝对值越大，信号越强。
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import axios from 'axios'
  
  export default {
    name: 'RecommendedPairs',
    setup() {
      const recommendedPairs = ref([])
      const currentTime = ref('')
  
      const fetchRecommendedPairs = async () => {
        try {
          const response = await axios.get('/api/recommended_pairs')
          recommendedPairs.value = response.data
          currentTime.value = new Date().toLocaleString('zh-CN', { hour12: false })
        } catch (error) {
          console.error('Error fetching recommended pairs:', error)
        }
      }
  
      onMounted(() => {
        fetchRecommendedPairs()
        setInterval(fetchRecommendedPairs, 60000)
      })
  
      return {
        recommendedPairs,
        currentTime
      }
    }
  }
  </script>
  
  <style scoped>
  .recommended-pairs {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
  }
  
  .recommended-pair {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 15px;
      padding: 20px;
      text-align: center;
      width: 200px;
      transition: all 0.3s ease;
      backdrop-filter: blur(5px);
  }
  
  .recommended-pair:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .recommended-pair h3 {
      margin: 0 0 15px 0;
      font-size: 18px;
      font-weight: 600;
  }
  
  .recommended-pair .score {
      font-size: 28px;
      font-weight: 700;
      color: var(--accent-color);
      margin-bottom: 15px;
  }
  
  .recommended-pair .direction {
      font-size: 16px;
      font-weight: 600;
      padding: 6px 12px;
      border-radius: 20px;
      display: inline-block;
      margin-bottom: 10px;
  }
  
  .recommended-pair .direction.long {
      background-color: rgba(52, 199, 89, 0.1);
      color: var(--long-color);
  }
  
  .recommended-pair .direction.short {
      background-color: rgba(255, 59, 48, 0.1);
      color: var(--short-color);
  }
  
  .recommended-pair .holding-time,
  .recommended-pair .signal-strength,
  .recommended-pair .adx,
  .recommended-pair .volatility {
      font-size: 14px;
      margin-top: 5px;
  }
  
  .score-explanation {
      text-align: center;
      margin-top: 25px;
      font-style: italic;
      color: var(--neutral-color);
      font-size: 14px;
  }
  
  .no-recommendations {
      text-align: center;
      padding: 20px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 15px;
      backdrop-filter: blur(5px);
  }
  
  .no-recommendations p {
      margin: 5px 0;
      color: var(--text-color);
  }
  </style>