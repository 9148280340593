<template>
  <div class="container">
    <h1>OKX RSI Monitor</h1>
    <RecommendedPairs />
    <HistoricalRecommendations />
    <TradingPairsTable />
    <div id="loadingOverlay" v-show="loading">
      <div class="spinner"></div>
    </div>
    <div id="message" :class="messageClass">{{ message }}</div>
  </div>
</template>

<script>
import RecommendedPairs from './components/RecommendedPairs.vue'
import HistoricalRecommendations from './components/HistoricalRecommendations.vue'
import TradingPairsTable from './components/TradingPairsTable.vue'
import { ref } from 'vue'

export default {
  name: 'App',
  components: {
    RecommendedPairs,
    HistoricalRecommendations,
    TradingPairsTable
  },
  setup() {
    const loading = ref(false)
    const message = ref('')
    const messageClass = ref('')

    const showMessage = (msg, isError = false) => {
      message.value = msg
      messageClass.value = isError ? 'error show' : 'success show'
      setTimeout(() => {
        messageClass.value = ''
      }, 3000)
    }

    return {
      loading,
      message,
      messageClass,
      showMessage
    }
  }
}
</script>

<style>
@import './assets/styles/main.css';

#loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid var(--accent-color);
    border-top: 5px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#message {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease;
    backdrop-filter: blur(5px);
}

#message.show {
    opacity: 1;
}

#message.success {
    background-color: rgba(52, 199, 89, 0.2);
    color: var(--long-color);
}

#message.error {
    background-color: rgba(255, 59, 48, 0.2);
    color: var(--short-color);
}
</style>